<template>
  <div class="add-ticket-manager">
    <div class="add-ticket-manager__close">
      <img src="@/assets/images/icons/x.svg" alt="close" @click="cancel"/>
    </div>
    <div class="add-ticket-manager__head">
      <h3 class="add-ticket-manager__title">Настройка этапа билеты</h3>
      <p class="add-ticket-manager__subtitle">Выберите ответственного за покупку билетов и <br>
        заполните маршрутную памятку при необходимости</p>
    </div>

    <div style="position:relative;">
      <VCustomInput placeholder="Выберите ответственного*" :error="errorEmployee"
                    v-model="employeeSearch" id="speciality" @input="showSelect = true" @click="showSelect = true"
                    class="add-ticket-manager__inp"/>
      <p class="add-ticket-manager__inp-error" v-if="employeesFiltered?.length < 1 && employeeSearch?.length !== 0">
        Такого сотрудника нет в компании
      </p>
      <p class="add-ticket-manager__inp-error"
         v-if="errorEmployee &&  employeeSearch?.length === 0">Заполните
        обязательное поле</p>

      <div v-if="showSelect && employeesFiltered.length > 0" class="add-ticket-manager__section-search-results">
        <div class="add-ticket-manager__section-search-result" v-for="item in employeesFiltered"
             :key="item.id" :class="{'add-ticket-manager__section-search-result_active':
                            ticketsData.employee_id === item.id,
                    }" @click="selectSpeciality(item)">
          {{ item.full_name }}
        </div>
      </div>
    </div>


    <div class="buying-tickets">
      <div :class="['buying-tickets__header', { 'bottom-border_show': !availableRouteMemo }]">
        <div class="buying-tickets__title">Маршрутная памятка</div>
        <VCheckbox v-model="availableRouteMemo"/>
      </div>

      <div v-if="availableRouteMemo" class="buying-tickets__route-memo">
        <VEditor v-model="ticketsData.route_memo.description" :error="errorRoute"
                 placeholder="Памятка к маршруту*" class="buying-tickets__route-memo-editor"/>
        <p v-if="errorRoute" class="add-ticket-manager__inp-error">Заполните обязательное
          поле</p>
      </div>

      <div v-if="availableRouteMemo" class="buying-tickets__images">
        <div class="buying-tickets__file-items">
          <VFileImage v-for="(file, index) in buyingTicketsResponce" :key="index" :file="file"
                      deleteBtn @delete="deleteFile(file.id)"
                      class="buying-tickets__images-list-file"/>

          <div v-if="buyingTicketsResponce.length <= MULTIPLE_SIZE" class="buying-tickets__images-upload">
            <input
                @change="changeFile"
                :accept="FILE_ACCEPTED"
                multiple
                type="file"
            >
            <img src="@/assets/images/icons/upload.svg" alt="upload">
            Загрузить файл
          </div>
        </div>
      </div>
    </div>

    <div class="add-ticket-manager__buttons">
      <VButton label="Добавить" bg="#0086B2" color="#fff" color-hover="#fff" @click="apply"
               class="add-ticket-manager__btn"/>
      <VButton label="Отменить" bg="#E4E7EE" bgHover="#BBC4CD" color="#1E3959" color-hover="#1E3959"
               class="add-ticket-manager__btn" @click="cancel"/>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import VEditor from "@/components/UI/VEditor/index.vue";
import vacanciesApi from "@/api/vacancies";
import {FILE_ACCEPTED, FILE_SIZE, FILE_TYPES, MULTIPLE_SIZE} from "@/constants";
import eventBus from "@/services/eventBus";

const props = defineProps({
  modelValue: Object,
  employees: Array,
  vacancyId: [String, Number],
})

const emit = defineEmits(["update:modelValue", "buyingCancel", "getFieldsVacancies"]);

const ticketsData = ref({
  stage: "tickets",
  employee_id: null,
  route_memo: {
    description: '',
    files: []
  },
})

const errorEmployee = ref(false)
const errorRoute = ref(false)
const buyingTickets = ref({})
const availableRouteMemo = ref(false)
const buyingTicketsResponce = ref([])
const employeeSearch = ref('')
const showSelect = ref(false)

const employeesFiltered = computed(() => {
  return props.employees?.filter((item) => {
    return item.full_name.toLowerCase().includes(employeeSearch.value.toLowerCase())
  })
})

const selectSpeciality = (item) => {
  ticketsData.value.employee_id = item.id
  employeeSearch.value = item.full_name
  showSelect.value = false
}

const apply = () => {
  if (!availableRouteMemo.value) {
    if (!ticketsData.value.employee_id) {
      errorEmployee.value = true
      return
    }
  }
  if (
      !!availableRouteMemo.value &&(
      !ticketsData.value.employee_id
      || !ticketsData.value.route_memo?.description
      || ticketsData.value.route_memo?.description.length < 1
  )) {
    if (!ticketsData.value.employee_id) {
      errorEmployee.value = true
    }
    if(!ticketsData.value.route_memo?.description) {
      errorRoute.value = true
    }
    return
  }
  if(!availableRouteMemo.value) delete ticketsData.value.route_memo
  emit('update:modelValue', ticketsData.value)
  emit('buyingCancel')
}

const cancel = () => {
  emit('buyingCancel')
  //TODO наверное нужно еще выполнить удаление файлов  "buying_tickets_files"
  buyingTicketsResponce.value = {}
  buyingTickets.value = {}
}

const deleteFile = (fileId) => {
  buyingTicketsResponce.value = buyingTicketsResponce.value.filter((el) => {
    return el.id !== fileId
  })
  ticketsData.value.route_memo.files = ticketsData.value.route_memo.files.filter((el) => {
    return el !== fileId
  })
}

const files = ref([])

const changeFile = async (e) => {
  if (e.target?.files?.length > 10) {
    eventBus.emit("errorRequest", {
      title: "Ошибка",
      description: `Прикрепляйте за раз не более ${MULTIPLE_SIZE} файлов`,
    });
    return;
  }
  e.target?.files?.forEach((el) => {
    const file = el;
    const fileSize = file.size / 1024 / 1024;
    const fileType = file.type;
    const formData = new FormData();
    formData.append("label", 'Фотография маршрута');
    formData.append('files[]', el);


    if (!FILE_TYPES.some((type) => fileType.includes(type))) {
      return;
    }
    if(fileSize > FILE_SIZE) {
      eventBus.emit("errorRequest", {
        title: "Ошибка",
        description: `Выберите файл размером меньше чем ${FILE_SIZE} mb`,
      });
    } else {
      sendFile(formData)
    }
  })
  emit('getFields')


  //TODO Удалить если все ок
  // if (e.target.files[0].size < maxImageSize) {
  //   const formData = new FormData();
  //   formData.append("label", 'Фотография маршрута');
  //   formData.append('files[]', e.target.files[0]);
  //
  //   await sendFile(formData)
  //   emit('getFields')
  // }
}

const sendFile = async (body) => {
  try {
    const response = await vacanciesApi.sendFileAttachments(props.vacancyId, body)
    if (response) {
      buyingTicketsResponce.value.push(response.upload[0])
      ticketsData.value.route_memo.files.push(response.upload[0]?.id)
    }
  } catch (error) {
    console.log(error)
  }
}

watch(availableRouteMemo, (event) => {
  if (!event) {
    ticketsData.value.route_memo = {
      description: '',
      files: []
    }
    buyingTicketsResponce.value = []
  }
})

watch(() => ticketsData.value?.route_memo?.description, (data) => {
  errorRoute.value = data?.length < 1;
})

watch(() => ticketsData.value.employee_id, (data) => {
  errorEmployee.value = !data;
})


</script>

<style scoped lang="scss">
.add-ticket-manager {
  position: relative;
  padding: 40px 24px 24px 24px;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__btn {
    width: 288px;
  }

  &__inp {
    width: auto;
  }

  &__inp-error {
    margin-top: 4px;
    font-size: 14px;
    color: #DD4646;
  }

  &__section-search-results {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    max-height: 128px;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid $light-gray-1;
    box-shadow: 2px 4px 24px 0 #0000001F;
    z-index: 9;
    overflow: auto;
  }

  &__section-search-result {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: $light-gray-1;
      color: $dark;
    }

  }
}

.buying-tickets {
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
  margin-top: 16px;

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__images {
    padding-top: 20px;
  }

  &__file-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 24px;
  }

  &__images-list-file {
    width: 187px;
  }

  &__images-upload {
    position: relative;
    width: 187px;
    height: 56px;
    padding: 0 23.5px;
    border-radius: 8px;
    background-color: $primary-blue;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    img {
      width: 24px;
      margin-right: 8px;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }
  }

  &__route-memo {
    width: 100%;
    margin-top: 20px;
  }

  &__route-memo-editor {
    width: 100%;
    height: 220px;
    margin-top: 20px;
  }
}

.bottom-border {
  &_show {
    margin-bottom: 51px;
  }
}
</style>