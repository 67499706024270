<template>
  <div class="add-steps">
    <div class="add-steps__close">
      <img src="@/assets/images/icons/x.svg" alt="close" @click="emit('cancel')" />
    </div>

    <div class="add-steps__head-steps">
      <h3 class="add-steps__title">Добавление этапа</h3>
      <p class="add-steps__subtitle">Выберите один или несколько необходимых этапов,<br>
        которые будут включены в процесс согласования</p>
    </div>

    <div class="add-steps__steps-el">
      <input  id="medical" class="add-steps__input" type="checkbox" v-model="isMedicalCheck"/>
      <label  class="add-steps__label" for="medical">Медицинская проверка</label>
    </div>
    <div class=" add-steps__steps-el">
      <input  id="tickets" class="add-steps__input" type="checkbox" v-model="isBuyingTickets"/>
      <label  class="add-steps__label" for="tickets">Покупка билетов</label>
    </div>

    <div class="add-steps__footer">
      <VButton
          class="add-steps__submit-btn" click-effect
          bg="#0086B2" color="#fff" color-hover="#fff"
          label="Добавить" @click="apply"
      />
      <VButton
          class="add-steps__cancel-btn"
          label="Отменить" bg="#E4E7EE"
          bgHover="#BBC4CD" color="#1E3959"
          color-hover="#1E3959" @click="emit('cancel')"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const emit = defineEmits(["addSteps", "cancel"]);

const isMedicalCheck = ref(false)
const isBuyingTickets = ref(false)

const apply = () => {
  if (isMedicalCheck.value) emit('addStep','medical')
  if (isBuyingTickets.value) emit('addStep','tickets')
  emit('cancel')
}

</script>

<style scoped lang="scss">
.add-steps {
  position: relative;
  padding: 40px 24px 24px 24px;
  height: 318px;


  &__title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__steps-el {
    display: flex;
    margin-bottom: 26px;
  }

  &__label {
    display: flex;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: $dark;
    cursor: pointer;
  }

  &__input {
    margin-left: 12px;
    position: relative;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid $light-gray-2;
    border-radius: 4px;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 2px;
      top: 1px;
      width: 14px;
      height: 14px;
      transform: scale(0);
      background-image: url('../../../assets/images/icons/check.svg');
      background-size: contain;
    }

    &:checked {
      border-color: $blue;

      &::before {
        transform: scale(1);
      }
    }
  }

  &__footer {
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: 36px;

    &>div {
      flex: 1 1 100%;
    }
  }
}
</style>