<template>
  <div class="add-participants" :class="isNotPublished? 'add-participants__isPublished' : 'add-participants__isNotPublished'">
    <ModalWindow v-if="showPopup" class="add-participants__modal">
      <AddResponsible @addEmployees="onAddEmployees" @cancel="onCancelResponsible"
                      :employees="props.employees" :data="currentModalData"
                      :selectedEmployeesList="selectedEmployeesList"/>
    </ModalWindow>

    <ModalWindow v-if="showModalBuyingTickets" class="add-participants__modal-buying-tickets">
      <AddTicketManager
          :vacancy-id="vacancyId"
          :employees="props.employees"
          @update:modelValue="addTicket"
          @buyingCancel="showModalBuyingTickets = false"

      />
    </ModalWindow>

    <ModalWindow v-if="showModalSteps" class="add-participants__modal-steps">
      <AddSteps
          @cancel="showModalSteps = false"
          @addStep="addStep"
      />
    </ModalWindow>
    <div v-if="isNotPublished" class="add-participants__head">
      <button class="add-participants__add-button" @click="onAddEmployee">
        <span>
          <img class="add-participants__add-icon" src="@/assets/images/icons/plus-route.svg" alt="plus-route">
        </span>Добавить согласованта
      </button>
      <button class="add-participants__add-button" @click="showModalSteps = true">
        <span>
          <img class="add-participants__add-icon" src="@/assets/images/icons/plus-route.svg" alt="plus-route">
        </span>Добавить этап
      </button>

    </div>
    <div class="add-participants__main">
      <div class="add-participants__drag-block">
        <div class="add-participants__draggable-blocked">
          <div class="add-participants__draggable-blocked-item">
            <div v-if="isNotPublished" class="add-participants__draggable-blocked-icon">
              <img  src="@/assets/images/icons/lock-route.svg" alt="lock">
            </div>
            <div class="add-participants__responsible-text">
              <div class="add-participants__vacancy-name">Новый отклик</div>
              <div class="add-participants__responsible-text-name">{{ owner }}</div>
            </div>
            <div class="spacer"></div>
          </div>
        </div>
        <div class="add-participants__employees">
          <draggable v-model="selectedEmployeesList" :key="selectedRoute" item-key="index" :animation="300"
                     :move="onMove">
            <template #item="{ element, index }">

              <div v-if="element?.stage === 'medical'" class="add-participants__documents">
                <div v-if="isNotPublished"  class="add-participants__responsible-icon">
                  <img src="@/assets/images/icons/six-dots.svg" alt="six-dots">
                </div>
                <div class="add-participants__responsible-text">
                  <div class="add-participants__vacancy-name">Медицинская проверка</div>
                  <div class="add-participants__responsible-text-name">{{ owner }}</div>
                </div>
                <span v-if="isNotPublished" class="add-participants__employee-delete"
                      @click="deleteEmployee(index)">
                    <img src="@/assets/images/icons/trash.svg" alt="trash">
                </span>
                <div v-else class="spacer"></div>
              </div>

              <div v-else-if="element?.stage === 'approving'" class="add-participants__documents">
                <div v-if="isNotPublished" class="add-participants__responsible-icon">
                  <img src="@/assets/images/icons/six-dots.svg" alt="six-dots">
                </div>
                <div class="add-participants__responsible-text">
                  <div class="add-participants__vacancy-name">Согласовант</div>
                  <div class="add-participants__responsible-text-name">{{ getName(element.employee_id) }}</div>
                </div>
                <span v-if="isNotPublished" class="add-participants__employee-delete"
                      @click="deleteEmployee(index)">
                    <img src="@/assets/images/icons/trash.svg" alt="trash">
                </span>
                <div v-else class="spacer"></div>
              </div>

              <div v-else-if="element?.stage === 'documents'" class="add-participants__documents">
                <div v-if="isNotPublished" class="add-participants__responsible-icon">
                  <img src="@/assets/images/icons/six-dots.svg" alt="six-dots">
                </div>
                <div class="add-participants__responsible-text">
                  <div class="add-participants__vacancy-name">Запрос документов</div>
                  <div class="add-participants__responsible-text-name">{{ owner }}</div>
                </div>
                <div class="spacer"></div>
              </div>


              <div v-else-if="element?.stage === 'tickets'" class="add-participants__documents"  :style="!!validate ? {marginBottom: '30px'}: {}">
                <div v-if="isNotPublished" class="add-participants__responsible-icon">
                  <img src="@/assets/images/icons/six-dots.svg" alt="six-dots">
                </div>
                <div
                    v-if="!element?.employee_id"
                    @click="onTicketAdd(index)"
                    class="add-participants__draggable-empty-text"
                    :class="!!validate && !element?.employee_id? 'add-participants__draggable-empty-error' : ''"
                >
                  <span class="add-participants__draggable-empty-plus">
                      <img src="@/assets/images/icons/plus-route.svg" alt="plus-route">
                    </span>
                  <div class="add-participants__draggable-empty-name">Настроить этап “Билеты”</div>
                </div>

                <div v-if="!!element?.employee_id" class="add-participants__responsible-text">
                  <div class="add-participants__vacancy-name">Покупка билетов</div>
                  <div class="add-participants__responsible-text-name">{{ getName(element.employee_id) }}</div>
                </div>
                <span v-if="isNotPublished" class="add-participants__employee-delete"
                      @click="deleteEmployee(index)">
                    <img src="@/assets/images/icons/trash.svg" alt="trash">
                  </span>
                <div v-else class="spacer"></div>
                <br>
                <p class="add-participants__inp-error" v-if="!!validate && !element?.employee_id">Настройте этап билеты</p>
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import draggable from 'vuedraggable';
import {useStore} from "vuex";
import AddResponsible from "@/components/ModalWindow/ModalBodyes/AddResponsible";
import ModalWindow from "@/components/ModalWindow/index.vue";
import AddTicketManager from '@/components/CreateVacancy/AddTicketManager'
import AddSteps from '@/components/CreateVacancy/AddSteps'

const props = defineProps({
  modelValue: Object,
  companyAccess: Object,
  employees: {
    type: Array,
    default: []
  },
  participantsError: {
    type: [Boolean, Object],
    default: false
  },
  routeName: String,
  vacancyId: [String, Number],
  vacancy: Object,
  owner: String,
  selectedRoute: Number,
  validate: Boolean,
})
const emit = defineEmits(["update:modelValue", 'routesValidate']);
const showPopup = ref(false)
const showModalBuyingTickets = ref(false)
const showModalSteps = ref(false)

const selectedEmployeesList = ref([])
const store = useStore()
const modalData = ref({
  employeeCheck: {
    type: 'epmloyees',
    title: 'Добавление участника',
    subtitle: 'Выберите одного или нескольких участников согласования',
  }
})
const editTicketIndex = ref(null)
const currentModalData = ref({})

const onAddEmployee = () => {
  showPopup.value = true
  currentModalData.value = modalData.value['employeeCheck']
}

const addStep = (step) => {
  selectedEmployeesList.value.push({stage: step})
}


const onCancelResponsible = (type) => {
  showPopup.value = false
}

const onAddEmployees = (employees) => {
  employees.forEach(employee => {
    selectedEmployeesList.value.push(
        {
          stage: 'approving',
          employee_id: employee.id,
        }
    )
  })
  showPopup.value = false
}

const organizeUpdate = () => {
  const updateForm = [...selectedEmployeesList.value]
  return updateForm
}

const isNotPublished = computed(() => {
  if (!props.vacancy?.is_published) return true
  return props.vacancy?.routes.length <= props.selectedRoute
})

const onMove = (event) => {
  if (!props.vacancy?.is_published) return true
  return props.vacancy?.routes.length <= props.selectedRoute
}

const onTicketAdd = (index) => {
  editTicketIndex.value = index
  showModalBuyingTickets.value = true
}

const deleteEmployee = (index) => {
  selectedEmployeesList.value.splice(index, 1)
}

const getName = (id) => {
  return props.employees.find((e) => e.id === id).full_name || "Имя не найдено"
}

const addTicket = (el) => {
  selectedEmployeesList.value[editTicketIndex.value] = el
  emit('routesValidate')
}

const form = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

watch(() => selectedEmployeesList.value,
    (currentValue) => {
      const organizedData = organizeUpdate()
      form.value = [...organizedData]
    },
    {deep: true}
);

watch(() => props.selectedRoute, () => {
  selectedEmployeesList.value = [...props.modelValue]
})


onMounted(() => {
  selectedEmployeesList.value = [...props.modelValue]
  // if ((props?.modelValue?.routes && Object.entries(props?.modelValue?.routes).length) ) {
  //   organizeReadSteps(props.modelValue.approvalRoute)
  // } else {
  //
  // }
})
</script>

<style scoped lang="scss">
.add-participants {
  margin-top: 24px;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 1.92px 1.92px 7.68px 0px #0000000A;

  &__isPublished {
    padding: 24px 20px 24px 18px;
  }

  &__isNotPublished {
    padding: 28px 20px 8px 0;
  }

  &__modal ::v-deep(.modal__body) {
    width: 544px;
    height: 477px;
    border-radius: 16px;
  }

  &__modal-buying-tickets ::v-deep(.modal__body) {
    width: 636px;
    border-radius: 16px;
  }

  &__modal-steps ::v-deep(.modal__body) {
    width: 544px;
    border-radius: 16px;
  }

  &__head {
    display: flex;
    margin-bottom: 20px;
    padding-right: 40px;
  }

  &__add-button {
    display: block;
    margin-right: 24px;
    padding: 8px 0;
    height: 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    border: none;
    background-color: transparent;
    color: #0086B2;
    cursor: pointer;
  }

  &__add-icon {
    position: relative;
    margin-left: 8px;
    left: -8px;
  }


  &__draggable-blocked-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 48px;
    color: $dark;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
  }

  &__draggable-empty-text {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    height: 48px;
    color: $blue;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 16px;
    background-color: $light;
    border-radius: 8px;
    background-color: transparent;
    border: 1px dashed $blue;
    cursor: pointer;
  }

  &__draggable-empty-error {
    border: 1px dashed $red;
  }

  &__draggable-empty-plus {
    display: block;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &__draggable-blocked-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  &__employees {
    margin-bottom: 20px;
  }

  &__employee {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    color: $dark;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__documents {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 48px;
    color: $dark;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__responsible-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  &__responsible-text {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    height: 48px;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 16px;
    background-color: $light;
    border-radius: 8px;
  }

  &__responsible-text-name {
    flex: 1 1 20%;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
  }

  &__vacancy-name {
    flex: 1 1 20%;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
  }

  .spacer {
    width: 20px;
  }

  &__inp-error {
    margin-top: 4px;
    margin-left: 40px;
    margin-bottom: 10px;
    font-size: 14px;
    color: $red;
    width: 100%
  }

  &__employee-delete {
    cursor: pointer;
  }
}
</style>