<template>
  <div class="coordination-routes">
    <ModalWindow class="coordination-routes__modal" v-if="modal">
      <div class="coordination-routes__modal-content">
        <div class="coordination-routes__close">
          <img src="@/assets/images/icons/x.svg" alt="close" @click="clickCancel"/>
        </div>
        <h3 class="coordination-routes__modal-title">
          {{ type === 'edit' ? 'Редактирование маршрута' : 'Создание маршрута' }} </h3>
        <div class="coordination-routes__inp-block">
          <VCustomInput placeholder="Введите название маршрута*" :error="error"
                        v-model="nameRoute" name="vacancyName" id="vacancyName" ref="vacancyName"
                        class="coordination-routes__inp"/>
          <p class="coordination-routes__inp-error" v-if="error">Заполните обязательное поле</p>
        </div>
        <VButton v-if="type === 'create'" @click="addSave" class="coordination-routes__modal-btn" label="Добавить"
                 bg="#0086B2" color="#fff"></VButton>
        <VButton v-if="type === 'edit'" @click="save" class="coordination-routes__modal-btn" label="Сохранить"
                 bg="#0086B2" color="#fff"></VButton>
        <VButton @click="clickCancel" class="coordination-routes__modal-btn" label="Отменить" bg="#E4E7EE"
                 bgHover="#BBC4CD" color="#1E3959" color-hover="#1E3959"></VButton>
      </div>
    </ModalWindow>
    <h3>Маршруты согласования</h3>
    <div class="coordination-routes__chips">
      <VChip
        v-for="(item, index) in routes"
        :key="index"
        :bg-color="index === props.modelValue ? '#F0F2F4' : ''"
        height="44"
        @click="selectRoute(index)"
      >
        <div>{{ item.name }}</div>
        <template v-if="!onlySwitch" #icon>
          <img v-if="isEdit(index) && modelValue.toString() === index.toString()" @click.stop="edit(index, item.name)"
               src="@/assets/images/icons/pencil.svg" alt="back">
        </template>
      </VChip>
    </div>

    <div v-if="!onlySwitch" class="coordination-routes__line"></div>

    <div v-if="!onlySwitch" @click="add" class="coordination-routes__btn">
      <img src="@/assets/images/icons/plus_blue_bold.svg" alt="back">
      Добавить маршрут
    </div>
  </div>
</template>

<script setup>

import VChip from "@/components/UI/VChip/index.vue";
import ModalWindow from "@/components/ModalWindow/index.vue";
import {computed, ref, watch} from "vue";
import VButton from "@/components/UI/VButton/index.vue";

const props = defineProps({
  modelValue: {
    type: Number,
  },
  routes: {
    type: [Array, Object]
  },
  isNotPublished: [Boolean, Number],
  onlySwitch: Boolean
})

const modal = ref(false)
const nameRoute = ref(null)
const type = ref('edit')
const error = ref(false)
const indexEditName = ref(0)
const emit = defineEmits(['update:modelValue', 'editRouteName', 'createRoute']);

const isEdit = (index) => {
  if(typeof props.isNotPublished === 'boolean') {
    return  true
  }
  return props.isNotPublished <= index
}

const edit = (index, name) => {
  type.value = 'edit'
  nameRoute.value = name;
  indexEditName.value = index
  modal.value = true
}

const add = () => {
  type.value = 'create'
  nameRoute.value = null;
  modal.value = true
}

const clickCancel = () => {
  modal.value = false
}


const selectRoute = (index) => {
  emit('update:modelValue', index)
}

const save = () => {
  emit('editRouteName', nameRoute.value, indexEditName.value)
  modal.value = false
}

const addSave = () => {
  if (nameRoute.value?.length < 1 || !nameRoute.value) {
    error.value = true
    return
  }
  emit('createRoute', nameRoute.value)
  modal.value = false
}

watch(nameRoute, () => {
  if (nameRoute.value !== null) {
    if (nameRoute.value.length > 0) {
      error.value = false
    } else {
      error.value = true
    }
  } else {
    error.value = false
  }
})

</script>

<style scoped lang="scss">
.coordination-routes {
  background-color: white;
  width: 266px;
  padding: 16px 12px;
  border-radius: 16px;

  h3 {
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.78px;
    letter-spacing: 0%;
  }

  .coordination-routes__chips {
    font-weight: 400;
    font-size: 14px;
    margin-top: 12px;
    cursor: pointer;
  }

  .coordination-routes__line {
    border-bottom: solid 1px #F0F2F4;
    margin: 16px 0;
  }

  .coordination-routes__btn {
    display: flex;
    align-items: center;
    color: #0086B2;
    cursor: pointer;
  }

  &__modal-content {
    padding: 40px 24px 24px 24px;
    position: relative;
    width: 342px;
    background-color: white;
    border-radius: 16px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__modal-title {
    text-align: center;
  }

  &__inp-error {
    font-size: 14px;
    color: #DD4646;
  }

  &__inp-block {
    margin-bottom: 24px;
  }

  &__inp {
    margin-top: 16px;
    margin-bottom: 4px;
  }

  &__modal-btn {
    margin-top: 12px;
  }
}
</style>