<template>
  <div v-if="fieldsVacancies.forms" class="create-vacancy">
    <div v-show="step === 1" class="create-vacancy__wrapper container">
      <router-link v-if="route.path.includes('vacancies')" to="/vacancies" class="create-vacancy__breadcrumbs">
        <img src="@/assets/images/icons/back-arrow.svg" alt="arrow">
        <p>Вакансии</p>
      </router-link>
      <router-link v-if="route.path.includes('template')" to="/templates" class="create-vacancy__breadcrumbs">
        <img src="@/assets/images/icons/back-arrow.svg" alt="arrow">
        <p>Шаблоны</p>
      </router-link>

      <div class="create-vacancy__header">
        <h1 class="create-vacancy__title main-title">{{ $route.meta.name }}</h1>

        <div v-if="!isPublished" class="create-vacancy__steps">
          <div class="create-vacancy__step create-vacancy__step_one"
               :class="{ 'create-vacancy__step_active': step === 1 }">
            <p v-if="step === 1">Шаг 1</p>

            <img v-if="step === 2" class="create-vacancy__step-check"
                 src="@/assets/images/icons/check-green-cr.svg" alt="check">


            <svg width="15" height="28" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0H0L15 14L0 28H15V14V0Z"
                    fill="white"/>
            </svg>
          </div>

          <div class="create-vacancy__step create-vacancy__step_two"
               :class="{ 'create-vacancy__step_active': step === 2 }">
            Шаг 2
          </div>
        </div>
        <div v-else class="create-vacancy__steps">
          <div class="create-vacancy__step create-vacancy__step_one"
               :class="{ 'create-vacancy__step_active': step === 1 }">
            <p v-if="step === 1">Шаг 1</p>

            <img v-if="step === 2" class="create-vacancy__step-check"
                 src="@/assets/images/icons/check-green-cr.svg" alt="check">

            <svg width="15" height="28" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0H0L15 14L0 28H15V14V0Z"
                    fill="white"/>
            </svg>
          </div>

          <div class="create-vacancy__step create-vacancy__step_two"
               :class="{ 'create-vacancy__step_active': step === 2 }">
            Шаг 2
          </div>
        </div>
      </div>
      <BasicInformation v-model="form" :salaryFromError="salaryFromError"
                        :workingConditions="fieldsVacancies.working_conditions" class="create-vacancy__block"/>
      <VerificationDocuments v-model="form" :companyAccess="fieldsVacancies.company_access"
                             :extendedForm="fieldsVacancies?.forms?.extended_form" :error="documentsError"
                             :vacancyId="vacancyId"
                             @add="addedDocument" class="create-vacancy__block"/>

      <div class="create-vacancy__btns" v-if="route.meta.type === 'vacancy'">
        <VButtonNew label="Сохранить в черновик" style-type="grey" class="create-vacancy__btn" link to="/vacancies" v-if="!isPublished"/>

        <VButtonNew label="Сохранить" style-type="grey" class="create-vacancy__btn"
                 @click="publish"
                 v-if="!!isPublished"/>

        <VButtonNew label="Следующий шаг" class="create-vacancy__btn" @click="next"
                 :disabled="disabledBtn"/>
      </div>

      <div class="create-vacancy__btns create-vacancy__template-btns" v-if="route.meta.type === 'template'">

        <VButtonNew v-if="!route.params.templateId" label="Сохранить шаблон" style-type="grey"
                 class="save-template-btn create-vacancy__btn" link to="/templates" :disabled="disabledBtn"/>

        <VButtonNew label="Следущий шаг"  v-if="!!isEdit"
                 class="create-vacancy__btn" @click="next"
                 :disabled="disabledBtn"  />

        <VButtonNew label="Сохранить в черновик" style-type="grey"
                 colorHover="#1E3959" class="create-vacancy__btn" link to="/vacancies"
                 v-if="route.params.templateId"/>

        <VButtonNew label="Следующий шаг" v-if="!isEdit"
                 class="create-vacancy__btn" @click="next"
                 :disabled="disabledBtn" />


<!--        <VButton v-if="route.params.templateId" label="Следующий этап" bg="#0086B2" color="#fff"-->
<!--                 class="create-vacancy__btn" @click="next" :disabled="disabledBtn"/>-->
      </div>
    </div>
    <div v-show="step === 2" class="create-vacancy__wrapper container">
      <div v-if="route.path.includes('vacancies')" @click="step = 1" class="create-vacancy__breadcrumbs">
        <img src="@/assets/images/icons/back-arrow.svg" alt="arrow">
        <p>Создание вакансии</p>
      </div>
      <div v-if="route.path.includes('template')" @click="step = 1" class="create-vacancy__breadcrumbs">
        <img src="@/assets/images/icons/back-arrow.svg" alt="arrow">
        <p>Создание шаблона</p>
      </div>

      <div class="create-vacancy__header">
        <h1 class="create-vacancy__title main-title">Настройка процесса согласования</h1>
        <div class="create-vacancy__steps">
          <div class="create-vacancy__step create-vacancy__step_one"
               :class="{ 'create-vacancy__step_active': step === 1 }">
            <p v-if="step === 1">Шаг 1</p>

            <img v-if="step === 2" class="create-vacancy__step-check"
                 src="@/assets/images/icons/check-green-cr.svg" alt="check">

            <svg width="15" height="28" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0H0L15 14L0 28H15V14V0Z"
                    fill="white"/>
            </svg>
          </div>

          <div class="create-vacancy__step create-vacancy__step_two"
               :class="{ 'create-vacancy__step_active': step === 2 }">
            Шаг 2
          </div>
        </div>
      </div>
      <p class="create-vacancy__description">Установите порядок участников согласования так, как вы привыкли
        нанимать <br>в вашей компании. Добавьте недостающих участников или удалите ненужных</p>

      <AddParticipants
          v-if="fieldsVacancies?.employees"
          v-model="form.routes[selectedRoute].stages"
          :validate="errorRoute"
          :route-name="form.routes[selectedRoute]?.route_name"
          :selectedRoute="selectedRoute"
          :owner="fieldsVacancies?.vacancy_owner_employee?.full_name"
          :vacancy-id="vacancyId"
          @routesValidate="errorRoute = false"

          :companyAccess="fieldsVacancies.company_access"
          :employees="fieldsVacancies?.employees"
          :vacancy="fieldsVacancies.vacancy"/>

      <div class="create-vacancy__btns" v-if="route.meta.type === 'vacancy'">
        <VButtonNew label="Сохранить в черновик" style-type="grey" class="create-vacancy__btn" link to="/vacancies" v-if="!isPublished"/>

        <VButtonNew label="Опубликовать вакансию"  class="create-vacancy__btn"
                 @click="createVacancies" :disabled="disabledBtn" v-if="!isPublished"/>

        <VButtonNew label="Опубликовать вакансию" class="create-vacancy__btn" @click="publish" v-else/>
      </div>

      <div class="create-vacancy__btns" v-if="route.meta.type === 'template'">
        <VButtonNew v-if="!editId && !route.params.templateId" label="Сохранить шаблон" style-type="grey"
                 class="create-vacancy__btn" @click="putVacancies('save')" :disabled="disabledBtn"/>
        <VButtonNew v-if="editId && !route.params.templateId" label="Сохранить шаблон" style-type="grey"
                 class="create-vacancy__btn" link to="/templates" :disabled="disabledBtn"/>

        <VButtonNew label="Сохранить в черновик" style-type="grey" class="create-vacancy__btn" link to="/vacancies"
                 v-if="route.params.templateId"/>

        <VButtonNew v-if="route.params.templateId" label="Опубликовать вакансию"
                 class="create-vacancy__btn" @click="createVacancies" :disabled="disabledBtn"/>
      </div>
    </div>
    <AccessClosed v-if="fieldsVacancies.blocked_by_contract"/>
    <div>
      <CoordinationRoutes
          v-model="selectedRoute"
          :routes="form.routes"
          @editRouteName="editRouteName"
          @createRoute="createRoute"
          v-show="step === 2"
          style="margin-top: 153px"
          :is-not-published="isNotPublished"
      />
    </div>
  </div>
</template>

<script setup>
import {reactive, ref, onMounted, watch, computed, onActivated, onUnmounted} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import useVuelidate from '@vuelidate/core';
import vacancies from '@/api/vacancies';
import BasicInformation from '@/components/CreateVacancy/BasicInformation';
import AccessClosed from '@/components/AccessClosed'
import VerificationDocuments from '@/components/CreateVacancy/VerificationDocuments'
import CoordinationRoutes from '@/components/CreateVacancy/CoordinationRoutes'
import AddParticipants from '@/components/CreateVacancy/AddParticipants'
import useOffsetTop from '@/hooks/getOffsetTopScroll'
import {debounce} from './../../services/helpers'

const router = useRouter();
const route = useRoute();
const customRouteLink = ref(`${route.matched[0].path}/${route.params.id}`)
const editId = route.params.id;

const errorRoute = ref(false)
const selectedRoute = ref(0)
const step = ref(1)
// const step = ref(2)
const form = reactive({
  vacancyName: '',
  speciality: '',
  specialityName: '',
  region: '',
  regionName: '',
  placeWork: '',
  description: `<p></p><p>Обязанности:</p><p>—&nbsp;</p><p>Требования:</p><p>—&nbsp;</p><p>Условия:</p><p>—&nbsp;</p>`,
  educationLevel: '',
  workExperience: '',
  schedule: '',
  travelingExpenses: '',
  isFixSalary: true,
  fixSalary: null,
  salaryFrom: null,
  salaryTo: null,
  workerFunctionsRequired: false,
  securityServiceEmployeeId: null,
  extendedSecurityServiceEmployeeId: null,
  extendedShowSecurityServiceEmployee: false,
  showSecurityServiceEmployee: false,
  directionLeaderEmployeeId: null,
  showDirectionLeaderEmployee: false,
  accessExtendedForm: false,
  documents: [],
  isPublished: false,
  employeesDrag: [],
  laborFunctionsApplicant: null,
  subdivision: '',
  agreementConditions: '',
  contractTerm: '',
  extendedFormId: null,
  routes: [
    {
      name: 'Маршрут №1',
      stages: []
    }
  ]
});

const participants = ref({})
const participantsError = ref({
  isMedicalError: false,
  isBuingTicketsError: false
})
const isCreateVacanciesDirty = ref(false)
const createEmployeesDrag = () => {
  if (fieldsVacancies.value.employees.length || !fieldsVacancies.value.employees) return
  if (fieldsVacancies.value.vacancy && fieldsVacancies.value.vacancy.employees_order[1] && fieldsVacancies.value.vacancy.employees_order[2]) {
    for (let epm in fieldsVacancies.value.vacancy.employees_order) {
      form.employeesDrag.push(fieldsVacancies.value.vacancy.employees_order[epm])
    }
    return;
  }

  for (let emp in fieldsVacancies.value.employees) {
    if (fieldsVacancies.value.employees[emp][0]) {

      form.employeesDrag.push({
        id: fieldsVacancies.value.employees[emp][0].id,
        role: emp
      })
    }
  }
}

const salaryFromRequired = (val) => {
  if (form.isFixSalary || route.meta.type !== 'vacancy') {
    return true
  } else {
    return !!val
  }
}

const salaryToRequired = (val) => {
  if (form.isFixSalary || route.meta.type !== 'vacancy') {
    return true
  } else {
    return !!val
  }
}

const fixSalaryRequired = (val) => {
  if (!form.isFixSalary || route.meta.type !== 'vacancy') {
    return true
  } else {
    return !!val
  }
}

const requiredDirectionLeaderEmployeeId = (val) => {
  if (!form.showDirectionLeaderEmployee) {
    return true
  } else {
    return !!val
  }
}

const requiredSecurityServiceEmployeeId = (val) => {
  if (!form.showSecurityServiceEmployee) {
    return true
  } else {
    return !!val
  }
}

const requiredExtendedSecurityServiceEmployeeId = (val) => {
  if (!form.extendedShowSecurityServiceEmployee) {
    return true
  } else {
    return !!val
  }
}

const routesValidate = (val) => {
  let valid = false
  val.forEach((item, index) => {
    item.stages.forEach(stage => {
      if (stage.stage === 'tickets') {
        if (!stage.employee_id) {
          selectedRoute.value = index
          valid = true
        }
      }
    })
  })
  errorRoute.value = valid
  return valid
}

const requiredField = (val) => {
  if (route.meta.type === 'vacancy' || route.params.templateId) {
    return !!val
  }
  if (route.meta.type === 'template') {
    return true
  }
}

const salaryMin = (val) => {
  if (!val || form.isFixSalary) return true
  if (Number(form.salaryFrom) > Number(form.salaryTo)) {
    return false
  } else {
    return true
  }
}
const isEdit = computed(() => {
  return route.path.includes('edit')
})

const editRouteName = (name, index) => {
  form.routes[index].name = name
}

const createRoute = (name) => {
  form.routes.push({
    name: name,
    stages: [
      {
        stage: 'documents',
      }
    ]
  })
  selectedRoute.value = form.routes.length - 1
}

const salaryFromError = ref(false)

const rules = computed(() => ({
  vacancyName: {requiredField},
  speciality: {requiredField},
  region: {requiredField},
  placeWork: {requiredField},
  description: {requiredField},
  educationLevel: {requiredField},
  workExperience: {requiredField},
  schedule: {requiredField},
  travelingExpenses: {requiredField},
  fixSalary: {fixSalaryRequired},
  directionLeaderEmployeeId: {requiredDirectionLeaderEmployeeId},
  securityServiceEmployeeId: {requiredSecurityServiceEmployeeId},
  extendedSecurityServiceEmployeeId: {requiredExtendedSecurityServiceEmployeeId},
}));

const isNotPublished = computed(() => {
  if (!fieldsVacancies.value.vacancy?.is_published) return true
  return fieldsVacancies.value.vacancy?.routes.length
})


const v$ = useVuelidate(rules, form);

const qualificationForm = ref([]);
const fieldsVacancies = ref([]);

const vacancyId = ref(null);
const disabledBtn = ref(false)

const isPublished = ref(false)
const hasParticipantsErrors = () => {
  let result = false
  if (form.buyingTickets && !form.ticketsBuyerEmployeeId) {
    participantsError.value.isBuingTicketsError = true
    result = true
  } else {
    participantsError.value.isBuingTicketsError = false
  }
  if (form.available_medical && !form.medicalEmployeeId) {
    participantsError.value.isMedicalError = true
    result = true
  } else {
    participantsError.value.isMedicalError = false
  }
  return result
}

const onAddParticipantsValidate = () => {
  hasParticipantsErrors()
}
const getFieldsVacancies = async (outerId) => {
  let id = null
  id = route.params.templateId ? route.params.templateId : vacancyId.value
  if (outerId) id = outerId;
  try {
    fieldsVacancies.value = await vacancies.getFieldsVacancies(id);
    if (route.name === 'NewVacancies') {
      form.routes[0].stages.push({
        stage: 'documents'
      })
    }

    if (route.name === 'NewTemplate') {
      form.routes[0].stages.push({
        stage: 'documents'
      })
    }

    if (editId || route.params.templateId) {
      isPublished.value = fieldsVacancies.value.vacancy.is_published
      form.regionName = fieldsVacancies.value.vacancy.region_name
      form.region = fieldsVacancies.value.vacancy.region_id
      form.specialityName = fieldsVacancies.value.vacancy.speciality_name
      form.speciality = fieldsVacancies.value.vacancy.speciality_id
      form.vacancyName = fieldsVacancies.value.vacancy.title
      form.placeWork = fieldsVacancies.value.vacancy.place_of_work
      form.workExperience = fieldsVacancies.value.vacancy.work_experience
      form.educationLevel = fieldsVacancies.value.vacancy.education_level
      form.schedule = fieldsVacancies.value.vacancy.schedule
      form.travelingExpenses = fieldsVacancies.value.vacancy.include_traveling_expenses ? 'TRUE' : 'FALSE'
      form.description = fieldsVacancies.value.vacancy.description
      form.workerFunctionsRequired = fieldsVacancies.value.vacancy.worker_functions_required ? 1 : 0
      form.isFixSalary = fieldsVacancies.value.vacancy.is_fix_salary
      form.fixSalary = fieldsVacancies.value.vacancy.fix_salary
      form.salaryFrom = fieldsVacancies.value.vacancy.salary_from
      form.salaryTo = fieldsVacancies.value.vacancy.salary_to
      form.directionLeaderEmployeeId = fieldsVacancies.value.vacancy.direction_leader_employee_id
      form.securityServiceEmployeeId = fieldsVacancies.value.vacancy.security_service_employee_id
      form.extendedSecurityServiceEmployeeId = fieldsVacancies.value.vacancy.extended_form_security_service_employee_id
      form.accessExtendedForm = fieldsVacancies.value.vacancy.access_extended_form
      form.isPublished = fieldsVacancies.value.vacancy.is_published
      form.subdivision = fieldsVacancies.value.vacancy.epartment
      form.agreementConditions = fieldsVacancies.value.vacancy.terms_of_contract
      form.contractTerm = fieldsVacancies.value.vacancy.duration_of_contract
      form.extendedFormId = fieldsVacancies.value.vacancy.extended_form_id
      form.documents = fieldsVacancies.value.vacancy.documents?.fields

      fieldsVacancies.value?.vacancy.routes.forEach((route, index) => {
        if (!form.routes[index]) {
          form.routes.push({
            name: route.name,
            stages: []
          })
        } else {
          form.routes[index].name = route.name
        }
        route.stages.forEach(stage => {

          switch (stage.stage) {
            case 'documents':
              form.routes[index].stages.push({
                stage: 'documents'
              })
              break
            case 'medical':
              form.routes[index].stages.push({
                stage: 'medical'
              })
              break
            case 'approving':
              form.routes[index].stages.push({
                stage: 'approving',
                employee_id: stage?.responsible?.id
              })
              break
            case 'tickets':
              form.routes[index].stages.push({
                stage: 'tickets',
                employee_id: stage?.responsible?.id,
                route_memo: stage?.route_memo ? {
                  description: stage?.route_memo.description,
                  files: stage?.route_memo.files.map(file => file.id),
                } : null
              })
              break
          }
        })
      })
    }
    // createEmployeesDrag()
  } catch (error) {
    console.log(error);
  }
};

const getCreateVacancies = async (isTemplate) => {
  try {
    let response = {}
    if(!!isTemplate) {
      response = await vacancies.getCreateTemplates();
    } else {
      response = await vacancies.getCreateVacancies();
    }
    vacancyId.value = response.id;
    await getFieldsVacancies();
  } catch (error) {
    console.log(error);
  }
};

const postCreateTemplate = async () => {
  if ((form.specialityName && !form.speciality) || (form.regionName && !form.region)) return
  if (form.salaryFrom && !form.isFixSalary) {
    if (Number(form.salaryFrom) > Number(form.salaryTo)) {
      salaryFromError.value = true
      return
    }
  }
  disabledBtn.value = true

  const body = {
    region_id: form.region,
    speciality_id: form.speciality,
    title: form.vacancyName,
    place_of_work: form.placeWork,
    work_experience: form.workExperience,
    education_level: form.educationLevel,
    schedule: form.schedule,
    include_traveling_expenses: form.travelingExpenses === 'TRUE' ? true : false,
    description: form.description,
    worker_functions_required: form.workerFunctionsRequired,
    is_fix_salary: form.isFixSalary,
    fix_salary: form.fixSalary,
    salary_from: form.salaryFrom,
    salary_to: form.salaryTo,
    routes: form.routes,
    ...participants.value
  }

  if (!isPublished.value) {
    body.direction_leader_employee_id = form.directionLeaderEmployeeId
    body.security_service_employee_id = form.securityServiceEmployeeId
    body.employees_order = {
      1: form.directionLeaderEmployeeId,
      2: form.securityServiceEmployeeId
    }

    if (!form.directionLeaderEmployeeId && form.securityServiceEmployeeId) {
      body.employees_order = {
        1: form.securityServiceEmployeeId
      }
    }

    body.access_extended_form = form.accessExtendedForm
    body.documents = form.documents
  }

  try {
    const response = await vacancies.postCreateTemplate(body);
    if (response.blocked_by_contract) {
      await router.push('/')
      return
    }
    if (response.success) await router.push(`/templates`);
  } catch (error) {
    console.log(error);
  }
};

const putVacancies = async (arg) => {
  try {
    const body = {
      region_id: form.region,
      speciality_id: form.speciality,
      title: form.vacancyName,
      place_of_work: form.placeWork,
      work_experience: form.workExperience,
      education_level: form.educationLevel,
      schedule: form.schedule,
      include_traveling_expenses: form.travelingExpenses === 'TRUE' ? true : false,
      description: form.description,
      worker_functions_required: form.workerFunctionsRequired == 1 ? true : false,
      is_fix_salary: form.isFixSalary,
      fix_salary: form.fixSalary,
      salary_from: form.salaryFrom,
      salary_to: form.salaryTo,
      department: form.subdivision,
      terms_of_contract: form.agreementConditions,
      duration_of_contract: form.contractTerm,
      extended_form_id: form.extendedFormId,
      routes: form.routes,
      documents: form.documents,
      ...participants.value
    }

    body.is_template = 1
    let response = await vacancies.putVacancies(vacancyId.value, body);


    if (response.blocked_by_contract) {
      await router.push('/')
      return
    }

    if (arg === 'save') {
      await router.push('/templates')
    }


  } catch (error) {
    console.log(error);
  }
};

const getOffsetTop = (element) => {
  let offset = element.offsetTop;
  if (element.offsetParent) {
    offset += getOffsetTop(element.offsetParent);
  }
  return offset;
}

const createVacancies = async () => {
  const validate = await v$.value.$validate();
  if (!!routesValidate(form.routes)) return
  isCreateVacanciesDirty.value = true
  if (!validate) {
    useOffsetTop()
    return
  }

  disabledBtn.value = true
  try {
    const response = await vacancies.createVacancies(vacancyId.value)
    if (response?.errors) {
      hasParticipantsErrors()
      return
    }
    if (response.blocked_by_contract) {
      await router.push('/')
      return
    }
    if (response.success) await router.push(`/vacancies`);
  } catch (error) {
    disabledBtn.value = false
    console.log(error.response.data.trace, 'errors');
  }
};

const createTemplate = async () => {
  const validate = await v$.value.$validate();
  if (!validate) return;

  disabledBtn.value = true
  try {
    const response = await vacancies.createTemplate(vacancyId.value, {
      action: 'create_template'
    })
    if (response.blocked_by_contract) {
      await router.push('/')
      return
    }
    if (response.success) await router.push(`/templates`);
  } catch (error) {
    disabledBtn.value = false
    console.log(error);
  }
};

const publish = async () => {
  const validate = await v$.value.$validate();
  if (!!routesValidate(form.routes)) return
  if (!validate) {
    useOffsetTop()
    return
  }
  await router.push(`/vacancies`);
}

const documentsError = ref(false)

const addedDocument = () => {
  if (form.documents.length) {
    documentsError.value = false
  }
}

window.v$ = v$
const next = async () => {
  const validate = await v$.value.$validate();
  let skipErrors = false
  v$.value.$errors.forEach((error) => {
    if (skipErrors) return;
    const el = document.getElementById(error.$property)
    const offset = 24
    const y = el.getBoundingClientRect().top + window.scrollY - el.getBoundingClientRect().height - offset;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
    skipErrors = true
  })
  if ((!validate || !form?.documents?.length) && route.meta.type !== 'template') {
    if (!form?.documents?.length) {
      documentsError.value = true
    }
    return
  }
  if ((!validate || !form?.documents?.length) && route.meta.type === 'template' && route.path.includes('create')) {
    if (!form?.documents?.length) {
      documentsError.value = true
    }
    return
  }
  documentsError.value = false
  step.value = 2
}

const debouncedPutVacancies = debounce(async () => await putVacancies(), 400)
const hideWatch = ref(true)
watch(form, async () => {
  if (isCreateVacanciesDirty.value) {
    hasParticipantsErrors()
  }
  if (route.meta.type === 'template' || route.params.templateId) {
    if (!hideWatch.value) debouncedPutVacancies()
  }
  if (route.meta.type === 'vacancy' || route.params.templateId) {
    if (!hideWatch.value) debouncedPutVacancies()
  }
});

onActivated(() => {
  window.addEventListener('popstate', function (event) {

    if (step.value === 2) {
      step.value = 1
    } else if (step.value === 1) {
      router.push('/vacancies')
    }
  });

  customRouteLink.value = `${route.matched[0].path}/${route.params.id}`
})

const preventBrowserHistory = (event) => {
  if (step.value === 2) {
    step.value = 1
  } else if (step.value === 1) {
    router.push('/vacancies')
  }
}

onMounted(async () => {
  window.addEventListener('popstate', preventBrowserHistory);
  customRouteLink.value = `${route.matched[0].path}/${route.params.id}`
  const templateId = await localStorage.getItem('templateId')
  if (!editId) {
    if (route.meta.type === 'vacancy') {
      await getCreateVacancies()
    } else if (route.meta.type === 'template') {
      if (route.params.templateId) {
        await getCreateVacancies()
        await debouncedPutVacancies()
      } else {
        await getCreateVacancies('template')
      }
    }
  } else if (templateId) {
    vacancyId.value = route.params.id
    getFieldsVacancies(templateId).then((data) => {
      debouncedPutVacancies()
    })
    localStorage.removeItem('templateId')
  } else {
    vacancyId.value = editId
    await getFieldsVacancies()
  }

  setTimeout(() => {
    hideWatch.value = false
  }, 2300)
});

onUnmounted(() => {
  window.removeEventListener('popstate', preventBrowserHistory)
})
</script>

<style scoped lang="scss">
.create-vacancy {
  padding-bottom: 45px;
  display: flex;
  gap: 32px;

  &__breadcrumbs {
    display: flex;
    align-items: center;
    color: $gray;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    p {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 400;
    }

    img {
      width: 20px;
    }
  }

  &__description {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }

  &__header {
    margin-top: 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__steps {
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
  }

  &__step {
    position: relative;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    user-select: none;
    background-color: #fff;
    color: $gray;

    &_one {
      width: 89px;

      svg {
        position: absolute;
        right: 0;
        top: 0;

        path {
          fill: #0086B2;
        }
      }
    }

    &_two {
      width: 71px;
    }

    &_active {
      background-color: #0086B2;
      color: #fff;
    }

    &_active {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &__wrapper {
    padding-top: 12px;
  }

  &__block {
    width: 100%;
    margin-top: 24px;
  }

  &__btns {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }



  &__template-btns {
    & > :first-child {
      width: 53.846%;
    }

    & > :last-child {
      width: 42.307%;

    }
  }

  &__btn {
    &:first-child {
      width: 42.307%;
    }

    &:last-child {
      width: 53.846%;


    }
  }
}
</style>