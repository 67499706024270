<template>
    <div class="document-template">
        <img src="@/assets/images/icons/document-gray.svg" class="document-template__doc-icon" alt="document">

        <div class="document-template__wrapper">
            <div class="document-template__name">{{ name }}</div>
            <p v-if="size" class="document-template__subtitle">{{size}}МБ</p>
            <p v-else class="document-template__subtitle">{{ required ? 'Обязательно' : 'Необязательно' }}</p>
        </div>

        <div v-if="!hideMenu" @click="showMenu = !showMenu" class="document-template__dots-btn">
            <img src="@/assets/images/icons/dots.svg" class="document-template__dots-icon" :class="`btn-${index}`" alt="dots">
        </div>

        <div v-if="showMenu" class="document-template__menu">
            <div @click="emit('edit')" class="document-template__menu-btn">
                <img src="@/assets/images/icons/pencil.svg" alt="pencil">
                Редактировать
            </div>
            <div @click="emit('remove')" class="document-template__menu-btn">
                <img src="@/assets/images/icons/delete-red.svg" alt="pencil">
                Удалить
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    required: {
        type: Boolean,
        default: false
    },
    index: {
        type: Number
    },
    hideMenu: {
        type: Boolean,
        default: false
    },
    size: {
        type: [Number, String],
        default: ''
    }
})

const emit = defineEmits(['edit', 'remove'])

const showMenu = ref(false)

onMounted(() => {
    document.addEventListener('click', (e) => {
        if (e.target.classList[1] !== `btn-${props.index}` &&
            e.target.classList[0] !== 'document-template__menu-btn' &&
            e.target.classList[0] !== 'document-template__menu') {
            showMenu.value = false
        }
    })
})
</script>

<style scoped lang="scss">
.document-template {
    position: relative;
    height: 56px;
    padding: 10px 12px;
    border-radius: 8px;
    background-color: $light;
    display: flex;
    align-items: center;

    &__menu {
        position: absolute;
        left: 0;
        top: 36px;
        z-index: 2;
        width: 174px;
        padding: 8px 12px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid $light-gray-1;
        box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.12);
    }

    &__menu-btn {
        padding: 0 8px;
        height: 32px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        user-select: none;
        cursor: pointer;

        &:first-child {
            color: $blue;
        }

        &:last-child {
            margin-top: 8px;
            color: $red;
        }

        img {
            width: 24px;
            margin-right: 4px;
        }
    }

    &__doc-icon {
        width: 24px;
    }

    &__wrapper {
        width: 95px;
        margin-left: 12px;
        display: flex;
        flex-direction: column;
    }

    &__name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 400;
    }

    &__subtitle {
        margin-top: 4px;
        color: $gray;
        font-size: 12px;
        font-weight: 400;
    }

    &__dots-btn {
        width: 20px;
        height: 20px;
        margin-left: 12px;
        cursor: pointer;
    }

    &__dots-icon {
        width: 20px;
    }
}
</style>
